import React from "react";
import FacilityQueries from "./FacilityQueries";
import HmoQueries from "./HmoQueries";

const Query = ({
	facilityQueries,
	loadingQuery,
	error,
	errorHmo,
	loadingHmo,
	HMOQueries,
}) => {
	const userRole = sessionStorage.getItem("user_role");

	return (
		<section>
			{userRole.includes("facility") || userRole === "super_admin" ? (
				<FacilityQueries
					facilityQueries={facilityQueries}
					loadingQuery={loadingQuery}
					error={error}
				/>
			) : (
				<HmoQueries
					HMOQueries={HMOQueries}
					errorHmo={errorHmo}
					loadingHmo={loadingHmo}
				/>
			)}
		</section>
	);
};

export default Query;
