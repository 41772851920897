
import HomeIcon from '@mui/icons-material/Home';
import VerifiedIcon from '@mui/icons-material/Verified';
import VerifiedUserIcon from '@mui/icons-material/VerifiedUser';
import AutoStoriesIcon from '@mui/icons-material/AutoStories';
import AssessmentIcon from '@mui/icons-material/Assessment';
import RoomPreferencesIcon from '@mui/icons-material/RoomPreferences';
 const SidebarData = [
    {
      icon: HomeIcon,
      heading: "Home",
      path: "/"
    },
    {
      icon: VerifiedIcon,
      heading: "Verification",
       path: "/verify"
    },
    {
      icon: VerifiedUserIcon,
      heading: "Authorization",
       path: "/authorization"
    },
    {
      icon: AutoStoriesIcon,
      heading: 'Claims',
       path: "/claims"
    },
    {
      icon: RoomPreferencesIcon,
      heading: 'Configurations',
       path: "/config"
    },
    {
      icon: AssessmentIcon,
      heading: 'Reports',
       path: "/reports"
    },
   
  ];
  export default SidebarData;