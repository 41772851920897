import { RiseLoader } from "react-spinners";

function Loader() {
	return (
		<center className="h-[100vh] mx-auto mt-[8rem]">
			<RiseLoader color="#36d7b7" size={10} />
		</center>
	);
}

export default Loader;
