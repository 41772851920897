import React from "react";
import Typography from "@mui/material/Typography";
 


export default function Bottom(props) {
	return (
		<Typography
			variant="body2"
			color="text.secondary"
			align="center"
			{...props}
		>
			{"Copyright © "}
			Designed for Plateau State Contributory Health Care Management Agency by{" "}
			<a className="text-green-500" href="https://instantdeposit.africa">
				Instant Deposit Ltd.
			</a>{" "}
			All Rights Reserved.
			{new Date().getFullYear()}
			{"."}
		</Typography>
	);
}