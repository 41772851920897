import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import "./style.css";
import SideData from "./SidebarData";
import LogoutIcon from "@mui/icons-material/Logout";

const Sidebar = () => {
	const navigate = useNavigate();
	const role = sessionStorage.getItem("user_role");
	const [selected, setSelected] = useState(() => {
		const initialIndex =
			window.location.pathname === "/home"
				? 0
				: window.location.pathname === "/verify"
				? 1
				: window.location.pathname === "/authorization"
				? 2
				: window.location.pathname === "/claims"
				? 3
				: window.location.pathname === "/config"
				? 4
				: 0;
		return initialIndex;
	});

	useEffect(() => {
		sessionStorage.setItem("selected_menu_item", selected.toString());
	}, [selected]);

	useEffect(() => {
		const storedIndex = sessionStorage.getItem("selected_menu_item");
		if (storedIndex) {
			setSelected(parseInt(storedIndex));
		}
	}, []);

	return (
		<nav className="sidebar lg:h-[100vh]">
			<div className="hidden lg:inline-flex font-pop">
				<div className="menu">
					{SideData.map((item, index) => {
						return (
							<div
								key={index}
								className={selected === index ? "menuItem active" : "menuItem"}
								onClick={() => setSelected(index)}
							>
								<item.icon
									style={
										selected === index ? { color: "green" } : { color: "black" }
									}
								/>

								<button
									type="button"
									onClick={() => navigate(item?.path)}
									disabled={
										item.heading === "Verification" && role === "hmo"
											? true
											: item.heading === "Configurations" &&
											  (role === "hmo" || role.includes("facility"))
											? true
											: item.heading === "Claims" && role === "primary_facility"
											? true
											: false
									}
									className={
										item.heading === "Verification" && role === "hmo"
											? "decoration-none cursor-default text-gray-400"
											: item.heading === "Configurations" &&
											  (role === "hmo" || role.includes("facility"))
											? "decoration-none cursor-default text-gray-400"
											: item.heading === "Claims" && role === "primary_facility"
											? "decoration-none cursor-default text-gray-400"
											: null
									}
								>
									{item.heading}
								</button>
							</div>
						);
					})}
					{/* signoutIcon */}
					<div
						className="mx-auto font-pop text-red-500 my-2 cursor-pointer"
						onClick={(e) => {
							e.preventDefault();
							sessionStorage.removeItem("email_user");
							sessionStorage.removeItem("user_role");
							sessionStorage.removeItem("user_id");
							navigate("/");
						}}
					>
						<LogoutIcon />
						<span>Logout</span>
					</div>
				</div>
			</div>
		</nav>
	);
};

export default Sidebar;
