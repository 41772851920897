import React from "react";
import img1 from "../images/hmo.png";
import img2 from "../images/loader.png";
import { useNavigate } from "react-router-dom";
import queryImage from "../images/queries.jpeg";
import "./Query.css";
import Badge from "@mui/material/Badge";
import MailIcon from "@mui/icons-material/Mail";

const FacilityQueries = ({ facilityQueries, loadingQuery, error }) => {
	const navigate = useNavigate();

	return (
		<div>
			<div className="flex items-center justify-center gap-x-2">
				<h3 className="font-cant font-semibold tracking-wider">QUERIES</h3>

				<Badge
					badgeContent={
						facilityQueries?.length > 0 ? facilityQueries?.length : "0"
					}
					color="primary"
					className={facilityQueries.length > 0 ? "animate-pulse" : ""}
				>
					<MailIcon color="action" />
				</Badge>
			</div>
			<div className="overflow-y-scroll Updates ">
				{facilityQueries?.length > 0 ? (
					facilityQueries?.map((item, index) => (
						<div
							key={index}
							onClick={() => {
								navigate(`/facilityquery/${index}`, { state: { item } });
							}}
							className="update mb-2 shadow-md w-full text-xs cursor-pointer"
						>
							<img
								src={item?.issued_by?.name === "Plaschema" ? img2 : img1}
								alt="logo"
								className="w-16 rounded-full border-[1px] border-gray-300"
							/>

							<div className="noti">
								<div className="space-y-[-3px] mb-2 ">
									<p className="tracking-wider">
										Issued By:
										<span className="font-semibold font-cant text-blue-500 ">
											{" "}
											{item?.issued_by?.name}
										</span>{" "}
									</p>
								</div>

								<p className="leading-5 font-semibold">
									{item?.query_message.length > 30
										? item?.query_message.slice(0, 30) + "..."
										: item?.query_message}
								</p>
							</div>
							<p
								className={`${
									item?.query_status === "unresolved"
										? "text-red-600"
										: "text-blue-600"
								}        text-[10px] tracking-wider font-semibold capitalize`}
							>
								{item?.query_status}
							</p>
						</div>
					))
				) : (
					<div className="mx-auto my-5" align="center">
						{loadingQuery && (
							<p className="italic text-xs font-semibold">Loading Queries...</p>
						)}
						<img
							src={queryImage}
							alt="logo"
							loading="lazy"
							className="w-32 rounded-md rotate-12 mt-10 shadow-lg animate-pulsate"
						/>

						<div className="mt-8">
							<h4 className="tracking-wide text-blue-500">{error}</h4>
							<p className="tracking-wider italic text-xs py-2">
								Queries will appear here !
							</p>
						</div>
					</div>
				)}
			</div>
		</div>
	);
};

export default FacilityQueries;
