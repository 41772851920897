import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import Axios from "axios";
import { api } from "../Api";
import { Button } from "antd";
import Copyright from "./Bottom";
import Swal from "sweetalert2";
import { FiEye, FiEyeOff } from "react-icons/fi";

const Login = ({ setShowReset }) => {
	const navigate = useNavigate();
	const [loading, setLoading] = useState(false);
	const [details, setDetails] = useState({ email: "", password: "" });
	const [showPassword, setShowPassword] = useState(false);

	const handleSubmit = async (e) => {
		e.preventDefault();
		setLoading(true);

		const params = JSON.stringify({
			email: details.email,
			password: details.password,
		});
		await Axios(`${api.baseURL}/login`, {
			method: "POST",
			data: params,
			headers: {
				"Content-Type": "application/json",
				Authorization: `${api.auth}`,
			},
		})
			.then((res) => {
				if (res.data.status === true) {
					const validUser = res?.data?.user?.name;
					const userID = res?.data?.user?.id;
					const userRole = res?.data?.user?.role;

					sessionStorage.setItem("email_user", validUser);
					sessionStorage.setItem("user_id", userID);
					sessionStorage.setItem("user_role", userRole);
					Swal.fire({
						text: ` Welcome ${validUser}`,
						icon: "success",
						toast: true,
						position: "top",
						//timerProgressBar: true,
						showConfirmButton: false,
						timer: 2000,
					});

					navigate("/");
				} else if (res.data.status === false) {
					Swal.fire({
						text: `${res.data.error}! Please check your Entries and try again.`,
						icon: "warning",
						toast: true,
						position: "top",
						//timerProgressBar: true,
						showConfirmButton: false,
						timer: 3000,
					});
				}
				setLoading(false);
			})
			.catch((err) => {
				console.log(err);
				setLoading(false);
				if (err.response.status === 500) {
					Swal.fire({
						text: `${err.response.data.error}`,
						icon: "error",
						toast: true,
						position: "top",
						//timerProgressBar: true,
						showConfirmButton: false,
						timer: 3000,
					});
				} else if (err.response.status === 404) {
					Swal.fire({
						text: `${err.response.data.message}`,
						icon: "error",
						toast: true,
						position: "top",
						//timerProgressBar: true,
						showConfirmButton: false,
						timer: 3000,
					});
				} else {
					Swal.fire({
						text: `${err.message}` || "Something went wrong",
						icon: "error",
						toast: true,
						position: "top",
						//timerProgressBar: true,
						showConfirmButton: false,
						timer: 3000,
					});
				}
			});
	};
	return (
		<section className="h-screen bg-gradient-to-r from-gray-300 via-gray-200 to-gray-300">
			<div className="px-6 h-full text-gray-800">
				<div className="flex xl:justify-center lg:justify-between justify-center items-center flex-wrap h-full g-6">
					<div className="xl:ml-20 xl:w-5/12 lg:w-5/12 md:w-8/12 mb-12 md:mb-0 mt-10">
						<form onSubmit={handleSubmit}>
							<div className="">
								<h3 className="text-lg lg:text-2xl my-3 mx-auto font-semibold font-cant">
									{" "}
									Enrollee Verification, Authorization and Claims Management -{" "}
									<abbr className="text-green-500">eVAC</abbr>
								</h3>
							</div>

							<div className="flex items-center my-2 before:flex-1 before:border-t before:border-gray-300 before:mt-0.5 after:flex-1 after:border-t after:border-gray-300 after:mt-0.5">
								<img
									src={`${process.env.PUBLIC_URL}/loader.png`}
									alt="brand"
									className="w-16 object-contain mx-3"
									height={100}
								/>
							</div>
							<h3 className="text-lg text-center font-semibold mx-4 my-2">
								Sign in
							</h3>
							<div className="mb-6">
								<input
									type="email"
									className="form-control block w-full px-4 py-2 text-xl font-normal text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
									placeholder="Email address"
									id="email"
									name="email"
									autoComplete="on"
									autoFocus
									value={details.email}
									onChange={(e) =>
										setDetails({ ...details, email: e.target.value })
									}
								/>
							</div>

							<div className="mb-6 relative">
								<input
									required
									className="form-control block w-full px-4 py-2 text-xl font-normal text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
									name="password"
									placeholder="Password"
									type={showPassword ? "text" : "password"}
									id="password"
									autoComplete="on"
									value={details.password}
									onChange={(e) =>
										setDetails({ ...details, password: e.target.value })
									}
								/>
								<button
									type="button"
									className="absolute top-3 right-3 text-gray-500"
									onClick={() => setShowPassword(!showPassword)}
								>
									{showPassword ? (
										<FiEyeOff className="text-xl" />
									) : (
										<FiEye className="text-xl" />
									)}
								</button>
							</div>
							<div className="mt-[-1.5rem] flex items-center justify-between mb-2 ">
								<button
									type="button"
									className="bg-transparent inline-block px-7 py-3 text-blue-500 font-medium text-xs lg:text-sm leading-snug capitalize rounded"
								>
									don't have an account?
								</button>
								<button
									type="button"
									onClick={() => {
										navigate("/reset");
										setShowReset(false);
									}}
									className="bg-transparent inline-block px-7 py-3 text-blue-500 font-medium text-xs lg:text-sm leading-snug capitalize rounded"
								>
									Forgot Password?
								</button>
							</div>

							<div className="text-center lg:text-left">
								<Button
									type="success"
									htmlType="submit"
									disabled={
										details.email === "" || details.password === ""
											? true
											: false
									}
									loading={loading}
									className="active:scale-[.98] active:duration-75 transition-all bg-green-500 text-white hover:bg-green-400"
								>
									Login
								</Button>
							</div>
						</form>
					</div>
					<Copyright sx={{ mt: 8, mb: 4 }} />
				</div>
			</div>
		</section>
	);
};

export default Login;
