import React, { useState } from "react";
import Typography from "@mui/material/Typography";
import { useNavigate } from "react-router-dom";

function Copyright(props) {
	return (
		<Typography
			variant="body2"
			color="text.secondary"
			align="center"
			{...props}
		>
			{"Copyright © "}
			Designed for Plateau State Contributory Health Care Management Agency by{" "}
			<a className="text-green-500" href="https://instantdeposit.africa">
				Instant Deposit Ltd.
			</a>{" "}
			All Rights Reserved.
			{new Date().getFullYear()}
			{"."}
		</Typography>
	);
}

const Reset = ({ setShowReset }) => {
	const navigate = useNavigate();
	const [email, setEmail] = useState("");

	const handleSubmit = (e) => {
		e.preventDefault();
	};
	return (
		<section className="h-screen bg-gradient-to-r from-gray-300 via-gray-200 to-gray-300">
			<div className="px-6 h-full text-gray-800">
				<div className="flex xl:justify-center lg:justify-between justify-center items-center flex-wrap h-full g-6">
					<div className="xl:ml-20 xl:w-5/12 lg:w-5/12 md:w-8/12 mb-12 md:mb-0 mt-10">
						<form onSubmit={handleSubmit}>
							<div className="">
								<h3 className="text-lg lg:text-3xl my-3 mx-auto font-semibold font-cant">
									{" "}
									Forgot Password?
								</h3>
							</div>

							<div className="flex items-center my-2 before:flex-1 before:border-t before:border-gray-300 before:mt-0.5 after:flex-1 after:border-t after:border-gray-300 after:mt-0.5">
								<img
									src={`${process.env.PUBLIC_URL}/loader.png`}
									alt="brand"
									className="w-16 object-contain mx-3"
									height={100}
								/>
							</div>

							<label className="flex flex-col space-x-3 mb-4 mt-3">
								<span
									className="text-gray-700 text-sm flex-auto ml-5"
									align="left"
								>
									<button
										type="button"
										onClick={() => {
											navigate("/");
											setShowReset(true);
										}}
										className="bg-transparent inline-block py-1 text-blue-500 font-medium text-xs lg:text-sm leading-snug capitalize rounded"
									>
										Back to Login
									</button>
								</span>
								<input
									type="email"
									className="form-control block w-full px-4 py-2 text-xl font-normal text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
									placeholder="Email address"
									id="email"
									name="email"
									autoComplete="email"
									autoFocus
									value={email}
									onChange={(e) => setEmail(e.target.value)}
								/>
							</label>

							<div className="text-center lg:text-left">
								<button
									type="submit"
									disabled={!email === "" ? true : false}
									className="mt-4 inline-block px-7 py-3 bg-blue-600 text-white font-medium text-sm leading-snug uppercase rounded shadow-md hover:bg-blue-700 hover:shadow-lg focus:bg-blue-700 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-blue-800 active:shadow-lg transition duration-150 ease-in-out"
								>
									Reset Password
								</button>
							</div>
						</form>
					</div>
					<Copyright sx={{ mt: 8, mb: 4 }} />
				</div>
			</div>
		</section>
	);
};

export default Reset;
