import React, { useState } from "react";
import { Button, Divider, Empty } from "antd";
import { ToastContainer, toast } from "react-toastify";
import { useParams } from "react-router-dom";
import Axios from "axios";
import { api } from "./Api";
import { IoArrowRedo } from "react-icons/io5";
import { BsFillChatTextFill } from "react-icons/bs";

const QuerySummary = ({ facilityQueries, getFacilityQueries }) => {
	const { id } = useParams();
	const role = sessionStorage.getItem("user_role");

	const userID = sessionStorage.getItem("user_id");
	const [loading, setLoading] = useState(false);
	const [queryMessage, setQueryMessage] = useState("");

	const queryItem = facilityQueries.find((item, indx) => indx === parseInt(id));

	const date = new Date(queryItem?.query_date);
	const options = {
		weekday: "short",
		day: "numeric",
		month: "short",
		year: "numeric",
		hour: "numeric",
		minute: "numeric",
		second: "numeric",
	};
	const localDateString = date.toLocaleString("en-US", options);
	// ********************************************************REPLY QUERY
	const onSubmit = (e) => {
		e.preventDefault();
		setLoading(true);
		const params = JSON.stringify({
			hmo_or_fac_id: Number(userID),
			message: queryMessage,
		});

		//
		Axios(`${api.baseURL}/referral/${queryItem?.query_id}/replyquery`, {
			method: "POST",
			data: params,
			headers: {
				"Content-Type": "application/json",
				Authorization: `${api.auth}`,
			},
		})
			.then((res) => {
				setQueryMessage("");

				toast.success(`${res.data.message}`, {
					position: toast.POSITION.TOP_CENTER,
					autoClose: 5000,
					hideProgressBar: true,
				});
			})
			.catch((err) => {
				console.log(err);

				toast.error("🤦 Error encountered, please try again!", {
					position: toast.POSITION.TOP_CENTER,
					autoClose: 5000,
					hideProgressBar: true,
				});
			})
			.finally(() => {
				setLoading(false);
				getFacilityQueries();
			});
	};

	return (
		<div className="h-[100vh] bg-white mx-auto w-full overflow-x-scroll">
			<ToastContainer />
			<div className="bg-[lavender] font-pop font-semibold m-6 rounded-md p-6 enter">
				<div className="flex items-center justify-between">
					<h3 className="tracking-wider py-2 text-center text-sm md:text-lg">
						Query
					</h3>

					<div className="text-[10px] tracking-wider">
						<p className="">
							ID: <span>#{queryItem?.query_id}</span>
						</p>
						<p>{localDateString}</p>
					</div>
				</div>
				<Divider />

				<div className="flex-col lg:flex lg:flex-row items-center justify-between text-xs md:text-sm capitalize tracking-wider">
					<div>
						<p>
							Issued by:{" "}
							<span className="text-blue-500">
								{queryItem?.issued_by?.name}
							</span>
						</p>
						<p>
							To:{" "}
							<span className="">
								{queryItem?.issued_to?.name || "No Data"}
							</span>
						</p>
					</div>
					<div>
						<p>
							Referral Code:{" "}
							<span className="text-green-500">{queryItem?.referral_code}</span>
						</p>
						<p>
							Enrollment ID: <span>{queryItem?.enrollment_id}</span>
						</p>
					</div>
				</div>
				<Divider />

				<div className="max-w-xl mx-auto bg-white shadow-lg rounded-lg overflow-hidden">
					<div className="text-center  p-4">
						<p>{queryItem?.query_message}</p>
					</div>
					<div className="bg-gray-100 p-4">
						<h2 className="text-sm font-semibold mb-2 tracking-wider text-center">
							Replies
						</h2>

						{queryItem?.reply && queryItem?.reply.length > 0 ? (
							<div className=" px-4">
								{queryItem?.reply?.map((item, index) => (
									<div
										className="py-4 text-sm text-center bg-white rounded-md px-2 mb-2"
										key={index}
									>
										<div className="flex-col justify-center lg:flex lg:flex-row lg:items-center lg:justify-between text-[10px] pb-3 ">
											<p className="flex items-center gap-x-2 text-green-700">
												<span>
													<IoArrowRedo className="text-black" />
												</span>
												Replied by: {item?.replied_by?.name}
											</p>
											<p className="">{item?.reply_date}</p>
										</div>
										<Divider>
											<BsFillChatTextFill className="text-green-400" />
										</Divider>
										<p className="text-gray-500 font-spec">
											{item?.reply_message}
										</p>
									</div>
								))}
								<Divider />
							</div>
						) : (
							<Empty description="No replies yet." />
						)}
					</div>

					{role.includes("admin") || queryItem?.reply?.length === 2 ? null : (
						<form
							onSubmit={onSubmit}
							className="flex-col items-center justify-center lg:flex lg:flex-row lg:justify-around mx-auto"
						>
							<textarea
								className="w-full lg:w-3/5 shadow border rounded p-3 form-input mt-1  block ring-black outline-none focus:ring"
								placeholder="Enter Reply"
								type="text"
								value={queryMessage}
								spellCheck="true"
								onChange={(e) => setQueryMessage(e.target.value)}
								rows={2}
								maxLength="100"
							/>
							<div className="flex justify-center my-3">
								<Button
									type="primary"
									htmlType="submit"
									loading={loading}
									disabled={!queryMessage ? true : false}
									className="bg-[#00B4D8] text-white"
								>
									Reply
								</Button>
							</div>
						</form>
					)}

					<Divider />
				</div>
			</div>
		</div>
	);
};

export default QuerySummary;
