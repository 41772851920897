import { useState, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import "./style.css";
import { useNavigate } from "react-router-dom";
import Avatar from "@mui/material/Avatar";
import img1 from "../../images/hmo.png";
import img2 from "../../images/loader.png";
import img3 from "../../images/phc.png";

const Header = () => {
	const navigate = useNavigate();
	const role = sessionStorage.getItem("user_role");
	const location = useLocation();
	const [open, setOpen] = useState(false);
	const [screenWidth, setScreenWidth] = useState(0);

	const trackScreenWidth = () => {
		const width = window.innerWidth;
		setScreenWidth(width);
		if (width > 1000) {
			setOpen(true);
		}
	};

	useEffect(() => {
		trackScreenWidth();
		window.addEventListener("resize", trackScreenWidth);
		return () => window.removeEventListener("resize", trackScreenWidth);
	}, [screenWidth]);

	const handleClose = () => {
		if (screenWidth < 950) {
			setOpen(false);
		}
	};

	return (
		<nav className="navbar">
			<div className="sticky top-0 z-50 flex-col items-center justify-between h-auto max-w-full bg-[#3CCF4E] mx-auto mt-[-3px] border-b-2 border-white">
				<Link to="/">
					<img
						src={`${process.env.PUBLIC_URL}/brand.jpg`}
						alt="brand"
						className="w-32 h-16 object-contain rounded-sm mt-1 pl-3"
					/>
				</Link>

				<div className="list-wrapper">
					<img
						src="https://cdn.iconscout.com/icon/free/png-512/bars-collection-view-application-grid-menu-44415.png"
						alt="Menu bars"
						style={{ opacity: !open ? 1 : 0 }}
						onClick={() => {
							setOpen(!open);
						}}
					/>

					<img
						src="https://cdn3.iconfinder.com/data/icons/e-commerce-simple-ui-elements/100/TWalsh__close1-512.png"
						alt="Menu cross"
						style={{ opacity: open ? 1 : 0 }}
						onClick={() => {
							setOpen(!open);
						}}
					/>

					<ul style={{ left: open ? "0" : "-100vw" }} className="lg:hidden">
						<li>
							<Link
								to="/"
								onClick={handleClose}
								style={{ color: location.pathname === "/" && "whitesmoke" }}
							>
								Home
							</Link>
						</li>
						<li>
							<Link
								to="/verify"
								onClick={handleClose}
								className={role === "hmo" ? "pointer-events-none" : ""}
								style={{
									color: location.pathname === "/verify" && "whitesmoke",
								}}
							>
								Verification
							</Link>
						</li>
						<li>
							<Link
								to="/authorization"
								onClick={handleClose}
								style={{
									color: location.pathname === "/authorization" && "whitesmoke",
								}}
							>
								Authorization
							</Link>
						</li>
						<li>
							<Link
								to="/claims"
								onClick={handleClose}
								className={
									role === "primary_facility" ? "pointer-events-none" : ""
								}
								style={{
									color: location.pathname === "/claims" && "lightgray",
								}}
							>
								Claims
							</Link>
						</li>
						<li>
							<Link
								to="/config"
								onClick={handleClose}
								className={
									role === "hmo" || role.includes("facility")
										? "pointer-events-none"
										: ""
								}
								style={{
									color: location.pathname === "/config" && "whitesmoke",
								}}
							>
								Configuration
							</Link>
						</li>
						<li>
							<Link
								exact="true"
								to="/reports"
								onClick={handleClose}
								style={{
									color: location.pathname === "/reports" && "whitesmoke",
								}}
							>
								Reports
							</Link>
						</li>
						<li
							className="text-red-500 cursor-pointer font-semibold"
							onClick={(e) => {
								e.preventDefault();
								sessionStorage.removeItem("email_user");
								sessionStorage.removeItem("user_role");
								sessionStorage.removeItem("user_id");
								navigate("/");
							}}
						>
							Logout
						</li>
					</ul>
				</div>
				<div className="flex items-center justify-between bg-white text-black max-w-full  h-[35px] mt-2 lg:mt-1 mx-auto px-5">
					<div className="flex items-center space-x-2">
						<Avatar
							alt="User Logo"
							src={
								role === "hmo"
									? img1
									: role.includes("facility")
									? img3
									: role.includes("admin")
									? img2
									: null
							}
							className="rounded-full border-2 border-gray-400"
							style={{ height: "32px" }}
						/>
						<h4 className="text-xs lg:text-sm font-semibold tracking-wider capitalize">
							{sessionStorage.getItem("email_user")}
						</h4>
					</div>

					<div>
						<h4 className="text-xs my-2 font-medium">
							ID:{" "}
							<span className="text-blue-500 font-semibold tracking-wider">
								{sessionStorage.getItem("user_id")}
							</span>
						</h4>
					</div>
				</div>
			</div>
		</nav>
	);
};

export default Header;
