import "./style.css";
import Img from "../../images/verify.png";
import Img2 from "../../images/auth.png";
import Img3 from "../../images/claim.png";
import Img4 from "../../images/report.png";
import { Divider } from "@mui/material";
import { motion } from "framer-motion";

const blackBox = {
	initial: {
		height: "20vh",
		bottom: 0,
	},
	animate: {
		height: "100vh",
		when: "afterChildren",
		transition: {
			duration: 2,
			ease: [0.87, 0, 0.13, 1],
		},
	},
};

const Home = () => {
	return (
		<motion.div
			initial="initial"
			animate="animate"
			variants={blackBox}
			className="h-[100vh] mx-auto overflow-y-scroll"
			align="center"
		>
			<h3 className="opacity-0 animate-fade-in font-cant font-semibold my-3 text-xl pt-[1rem] lg:pt-3">
				Enrollee Verification, Authorization and Claims Management -{" "}
				<abbr className="text-green-500">eVAC</abbr>
			</h3>
			<div className="mt-20">
				{/* Verification */}
				<div className="group flex flex-col md:flex md:flex-row items-center justify-around py-3 my-3">
					<img
						src={Img}
						alt="L-1"
						className="group-hover:animate-pulse w-20 lg:w-28 jello"
					/>
					<div className="w-[50%]">
						<h3 className="text-lg font-semibold font-pop md:text-xl">
							Enrollee Verification
						</h3>
						<p className="justify-center text-sm my-2">
							Generate{" "}
							<abbr className="text-green-500 font-semibold">
								Encounter code
							</abbr>{" "}
							if beneficiary is enrolled on the system and subscription is
							valid.
						</p>
					</div>
				</div>
				<Divider />
				{/* Auth */}
				<div className="group flex flex-col-reverse md:flex md:flex-row items-center justify-around py-3 my-7">
					<div className="w-[50%]">
						<h3 className="text-lg font-semibold font-pop md:text-xl">
							Authorization Issuance
						</h3>
						<p className="justify-center text-sm my-2">
							Generate{" "}
							<abbr className="text-green-500 font-semibold">
								Referral code
							</abbr>
							, for a beneficiary to receive secondary services.
						</p>
					</div>
					<img
						src={Img2}
						alt="L-1"
						className="group-hover:animate-pulse w-20 lg:w-28 jello"
					/>
				</div>
				<Divider />
				{/* Claims */}
				<div className="group flex flex-col md:flex md:flex-row items-center justify-around py-3 my-7">
					<img
						src={Img3}
						alt="L-1"
						className="group-hover:animate-pulse w-20 lg:w-28 jello"
					/>
					<div className="w-[50%]">
						<h3 className="text-lg font-semibold font-pop md:text-xl">
							Claims Processing
						</h3>
						<p className="justify-center text-sm my-2">
							<abbr className="text-green-500 font-semibold">
								Claims Application
							</abbr>
							, for sevices rendered to beneficiary.
						</p>
					</div>
				</div>
				<Divider />
				{/* reports */}
				<div className="group flex flex-col-reverse md:flex md:flex-row items-center justify-around py-3 my-7">
					<div className="w-[50%]">
						<h3 className="text-lg font-semibold font-pop md:text-xl">
							Reports
						</h3>
						<p className="justify-center text-sm my-2">
							View {""}
							<abbr className="text-green-500 font-semibold">Reports</abbr>, for
							all transactions carried out by user.
						</p>
					</div>
					<img
						src={Img4}
						alt="L-1"
						className="group-hover:animate-pulse w-20 lg:w-28 jello"
					/>
				</div>
			</div>
		</motion.div>
	);
};

export default Home;
