import React, { useState } from "react";
import img2 from "../images/loader.png";
import claimImage from "../images/claims.jpeg";
import "./Query.css";
import Badge from "@mui/material/Badge";
import MailIcon from "@mui/icons-material/Mail";

const FacilityDisputes = () => {
	const [facilityDisputes] = useState([]);

	return (
		<div>
			<div className="flex items-center justify-center gap-x-2">
				<h3 className="font-cant font-semibold tracking-wider">
					CLAIM DISPUTES
				</h3>

				<Badge
					badgeContent={
						facilityDisputes?.length > 0 ? facilityDisputes?.length : "0"
					}
					color="error"
					className={facilityDisputes.length > 0 ? "animate-pulse" : ""}
				>
					<MailIcon color="action" />
				</Badge>
			</div>
			<div className="overflow-y-scroll Updates">
				{facilityDisputes?.length > 0 ? (
					facilityDisputes?.map((item, index) => (
						<div key={index} className="update mb-2 shadow-md w-full">
							<img
								src={item?.personnel.includes("Plaschema") && img2}
								alt="logo"
								className="w-16 rounded-full"
							/>

							<div className="noti">
								<div className="space-y-[-3px] mb-2">
									<p className="tracking-wider">
										From:
										<span className="font-semibold font-cant text-blue-500 ">
											{" "}
											{item?.personnel}
										</span>{" "}
									</p>
									<p className="tracking-wider">
										Referral Code:
										<span className="font-semibold font-cant text-green-500">
											{" "}
											{item?.referralCode}
										</span>
									</p>
								</div>

								<p className="leading-5 font-semibold">{item?.queryBody}</p>
								<div className="flex items-center justify-between text-xs">
									<p className="my-2">{item?.dateQueried}</p>
								</div>
							</div>
						</div>
					))
				) : (
					<div className="mx-auto my-5" align="center">
						<img
							src={claimImage}
							alt="logo"
							loading="lazy"
							className="w-32 rounded-md rotate-12 mt-10 shadow-lg animate-pulsate"
						/>
						<div className="mt-8">
							<p className="tracking-wider italic text-xs py-2">
								All Claim Disputes will appear here !
							</p>
						</div>
					</div>
				)}
			</div>
		</div>
	);
};

export default FacilityDisputes;
