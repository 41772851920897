import React from "react";
import FacilityDisputes from "./FacilityDisputes";



const Disputes = () => {
 //const userRole = sessionStorage.getItem("user_role");

  return (
  
     <section>
     <FacilityDisputes />
    </section>
    
  );
};

export default Disputes;