import React from "react";
import QueryColumn from "./Query";
import "./style.css";
import DisputesColumn from "./Disputes";

const RightSide = ({
	facilityQueries,
	loadingQuery,
	error,
	errorHmo,
	loadingHmo,
	HMOQueries,
}) => {
	return (
		<div className="RightSide pt-[15rem] md:pt-[0.1rem] lg:pt-10 px-1">
			<div>
				<QueryColumn
					facilityQueries={facilityQueries}
					loadingQuery={loadingQuery}
					error={error}
					HMOQueries={HMOQueries}
					errorHmo={errorHmo}
					loadingHmo={loadingHmo}
				/>
			</div>
			<div className="my-10">
				<DisputesColumn />
			</div>
		</div>
	);
};

export default RightSide;
