import React, { useState, useEffect, lazy, Suspense, useCallback } from "react";
import { Routes, Route, useLocation } from "react-router-dom";
import "./App.css";
import "./index.css";
import Home from "./components/Home";
import Footer from "./components/Footer";
import Login from "./Login/Login";
import Loading from "../src/components/Loading";
import Header from "./components/Header/Header";
import NoPage from "./NoPage";
import Sidebar from "./components/Sidebar/Sidebar";
import QueryThread from "./rightColumn/RightColumn";
import Reset from "./Reset";
import Axios from "axios";
import { api } from "./Api";
import QuerySummary from "./QuerySummary";
import QueryHMOSummary from "./QueryHMOSummary";
import PageLoader from "./PageLoader";
import { AnimatePresence } from "framer-motion";

const Verification = lazy(() => import("./components/Verification"));
const Authorization = lazy(() => import("./components/Authorization"));
const Configuration = lazy(() => import("./components/Config"));
const Claims = lazy(() => import("./components/Claims"));
const Reports = lazy(() => import("./components/Reports"));

function App() {
	const userID = sessionStorage.getItem("user_id");
	const userName = sessionStorage.getItem("email_user");
	const [loading, setLoading] = useState(true);
	const location = useLocation();

	const [showReset, setShowReset] = useState(true);
	const [facilityQueries, setFacilitiesQueries] = useState([]);
	const [loadingQuery, setLoadingQuery] = useState(false);
	const [error, setError] = useState("");
	const [HMOQueries, setHMOQueries] = useState([]);
	const [loadingHmo, setLoadingHmo] = useState(false);
	const [errorHmo, setErrorHmo] = useState("");

	useEffect(() => {
		setTimeout(() => {
			setLoading(false);
		}, 1000);
	}, []);

	// *********************************************** GET FACILITY QUERIES

	const getFacilityQueries = useCallback(() => {
		setLoadingQuery(true);
		const facID = Number(userID);

		Axios(`${api.baseURL}/referral/${facID}/allquery`, {
			method: "GET",
			headers: {
				"Content-Type": "application/json",
				Authorization: `${api.auth}`,
			},
		})
			.then((res) => {
				if (res.data.status === true) {
					setFacilitiesQueries(res?.data?.data);
				} else if (res.data.status === false) {
					setError(` No Queries for ${userName} yet...`);
				}
			})
			.catch((err) => {
				console.log(err);
			})
			.finally(() => {
				setLoadingQuery(false);
			});
	}, [userName, userID]);

	useEffect(() => {
		getFacilityQueries();
	}, [getFacilityQueries]);

	useEffect(() => {
		const interval = setInterval(() => {
			getFacilityQueries();
		}, 60000);

		return () => {
			clearInterval(interval);
		};
	}, [getFacilityQueries]);

	/// ************************************************************************GET HMO QUERIES

	const getHMOQueries = useCallback(() => {
		setLoadingHmo(true);

		const hmoID = Number(userID);

		Axios(`${api.baseURL}/referral/${hmoID}/allquery`, {
			method: "GET",
			headers: {
				"Content-Type": "application/json",
				Authorization: `${api.auth}`,
			},
		})
			.then((res) => {
				if (res.data.status === true) {
					setHMOQueries(res?.data?.data);
				} else if (res.data.status === false) {
					setErrorHmo(` No Queries for ${userName} yet...`);
				}
			})
			.catch((err) => {
				console.log(err);
			})
			.finally(() => {
				setLoadingHmo(false);
			});
	}, [userName, userID]);

	useEffect(() => {
		getHMOQueries();
	}, [getHMOQueries]);

	useEffect(() => {
		const interval = setInterval(() => {
			getHMOQueries();
		}, 60000);

		return () => {
			clearInterval(interval);
		};
	}, [getHMOQueries]);
	return (
		<div className="App">
			{loading ? (
				<Loading />
			) : (
				<div className="">
					{!sessionStorage.getItem("email_user") ? (
						showReset ? (
							<Login setShowReset={setShowReset} />
						) : (
							<Reset setShowReset={setShowReset} />
						)
					) : (
						<>
							<Header />
							<div className="grid grid-cols-1 md:grid-cols-[auto, 23rem] lg:grid-cols-[13rem,auto,23rem] px-2">
								<div className="column">
									<Sidebar />
								</div>
								<div className="column h-[100vh]">
									<AnimatePresence>
										<Suspense fallback={<PageLoader />}>
											<Routes location={location} key={location.pathname}>
												<Route path="/" element={<Home />} />
												<Route path="/reset" element={<Reset />} />
												<Route
													exact
													path="/verify"
													element={<Verification />}
												/>

												<Route
													exact
													path="/authorization"
													element={<Authorization />}
												/>
												<Route exact path="/claims" element={<Claims />} />
												<Route
													exact
													path="/config"
													element={<Configuration />}
												/>
												<Route exact path="/reports" element={<Reports />} />
												<Route
													path="/facilityquery/:id"
													element={
														<QuerySummary
															getFacilityQueries={getFacilityQueries}
															facilityQueries={facilityQueries}
														/>
													}
												/>
												<Route
													path="/hmoquery/:id"
													element={
														<QueryHMOSummary
															getHMOQueries={getHMOQueries}
															HMOQueries={HMOQueries}
														/>
													}
												/>
												{/* 👇️ when no other routes match */}
												<Route path="*" element={<NoPage />} />
											</Routes>
										</Suspense>
									</AnimatePresence>
								</div>

								<div className="">
									<QueryThread
										facilityQueries={facilityQueries}
										loadingQuery={loadingQuery}
										error={error}
										HMOQueries={HMOQueries}
										errorHmo={errorHmo}
										loadingHmo={loadingHmo}
									/>
								</div>
							</div>
							<Footer />
						</>
					)}
				</div>
			)}
		</div>
	);
}

export default App;
