//import { CubeGrid } from "better-react-spinkit";

function Loading() {
  return (
    <center style={{ display: "grid", placeItems: "center", height: "100vh", backgroundColor: "whitesmoke"}}>
      <div className="rounded-full">
        <img
          src={`${process.env.PUBLIC_URL}/loader.png`}
          alt="brand"
          className="w-36 object-contain rounded-md animate-spin p-2"
          height={100}
        />
      </div>
    </center>
  );
}

export default Loading;
