import React, { useEffect, useState } from "react";
import { Button, Divider, Empty } from "antd";
import { ToastContainer, toast } from "react-toastify";
import { useParams } from "react-router-dom";
import Axios from "axios";
import { api } from "./Api";
import { IoArrowRedo } from "react-icons/io5";
import { BsFillChatTextFill } from "react-icons/bs";

const QueryHMOSummary = ({ HMOQueries, getHMOQueries }) => {
	const { id } = useParams();
	const role = sessionStorage.getItem("user_role");
	const userID = sessionStorage.getItem("user_id");

	const [loading, setLoading] = useState(false);
	const [queryMessage, setQueryMessage] = useState("");
	const [resolveLoading, setResolveLoading] = useState(false);
	const [isDisabled, setIsDisabled] = useState(false);

	const queryItem = HMOQueries.find((item, indx) => indx === parseInt(id));

	const date = new Date(queryItem?.query_date);
	const options = {
		weekday: "short",
		day: "numeric",
		month: "short",
		year: "numeric",
		hour: "numeric",
		minute: "numeric",
		second: "numeric",
	};
	const localDateString = date.toLocaleString("en-US", options);

	// ********************************************************REPLY QUERY

	const onSubmit = (e) => {
		e.preventDefault();
		setLoading(true);
		const params = JSON.stringify({
			hmo_or_fac_id: Number(userID),
			message: queryMessage,
		});

		//
		Axios(`${api.baseURL}/referral/${queryItem?.query_id}/replyquery`, {
			method: "POST",
			data: params,
			headers: {
				"Content-Type": "application/json",
				Authorization: `${api.auth}`,
			},
		})
			.then((res) => {
				setQueryMessage("");

				toast.success(`${res.data.message}`, {
					position: toast.POSITION.TOP_CENTER,
					autoClose: 5000,
					hideProgressBar: true,
				});
			})
			.catch((err) => {
				console.log(err);

				toast.error("🤦 Error encountered, please try again!", {
					position: toast.POSITION.TOP_CENTER,
					autoClose: 5000,
					hideProgressBar: true,
				});
			})
			.finally(() => {
				setLoading(false);
				getHMOQueries();
			});
	};

	// ****************************************************** RESOLVE A QUERY
	const resolveQuery = (message) => {
		setResolveLoading(true);
		const params = JSON.stringify({
			hmo_or_fac_id: Number(userID),
			message: message,
		});

		Axios(`${api.baseURL}/referral/${queryItem?.query_id}/closequery`, {
			method: "POST",
			data: params,
			headers: {
				"Content-Type": "application/json",
				Authorization: `${api.auth}`,
			},
		})
			.then((res) => {
				console.log(res);

				toast.success(`${res?.data?.message}`, {
					position: toast.POSITION.TOP_CENTER,
					autoClose: 5000,
					hideProgressBar: true,
				});
			})
			.catch((err) => {
				console.log(err);

				toast.error("🤦 Error encountered.", {
					position: toast.POSITION.TOP_CENTER,
					autoClose: 4000,
					hideProgressBar: true,
				});
			})
			.finally(() => {
				setResolveLoading(false);
				if (message === "resolved") {
					window.location.replace("/authorization");
				} else {
					window.location.replace("/");
				}
			});
	};

	// ********************************************************************** BUTTON ACTIONS
	useEffect(() => {
		if (queryItem?.issued_by?.id === 34000 && role.includes("admin")) {
			setIsDisabled(false);
		} else if (queryItem?.issued_by?.name === "Plaschema" && role === "hmo") {
			setIsDisabled(false);
		} else if (queryItem?.query_status === "resolved") {
			setIsDisabled(true);
		} else {
			setIsDisabled(false);
		}
	}, [role, queryItem?.issued_by, queryItem?.query_status]);

	return (
		<div className="h-[100vh] bg-white mx-auto w-full overflow-x-scroll">
			<ToastContainer />

			<div className="bg-[lavender] font-pop font-semibold my-14 rounded-md mx-10 p-6 enter">
				<div className="flex items-center justify-between">
					<h3 className="tracking-wider py-2 text-center text-sm md:text-lg">
						Query
					</h3>

					<div className="text-[10px] tracking-wider">
						<p className="">
							ID: <span>#{queryItem?.query_id}</span>
						</p>
						<p>{localDateString}</p>
					</div>
				</div>
				<Divider />

				<div className="flex-col lg:flex lg:flex-row items-center justify-between text-xs md:text-sm capitalize tracking-wider">
					<div>
						<p>
							Issued by:{" "}
							<span className="text-blue-500">
								{queryItem?.issued_by?.name}
							</span>
						</p>
						<p>
							To:{" "}
							<span className="">
								{queryItem?.issued_to?.name || "No Data"}
							</span>
						</p>
					</div>
					<div>
						<p>
							Referral Code:{" "}
							<span className="text-green-500">{queryItem?.referral_code}</span>
						</p>
						<p>
							Enrollment ID: <span>{queryItem?.enrollment_id}</span>
						</p>
					</div>
				</div>
				<Divider />

				<div className="max-w-xl mx-auto bg-white shadow-lg rounded-lg overflow-hidden">
					<div className="text-center  p-4">
						<p>{queryItem?.query_message}</p>
					</div>
					<div className="bg-gray-100 p-4">
						<h2 className="text-sm font-semibold mb-2 tracking-wider text-center">
							Replies
						</h2>

						{queryItem?.reply && queryItem?.reply.length > 0 ? (
							<div className=" px-4">
								{queryItem?.reply?.map((item, index) => (
									<div
										className="py-4 text-sm text-center bg-white rounded-md px-2 mb-2"
										key={index}
									>
										<div className="flex-col justify-center lg:flex lg:flex-row lg:items-center lg:justify-between text-[10px] pb-3 ">
											<p className="flex items-center gap-x-2 text-green-700">
												<span>
													<IoArrowRedo className="text-black" />
												</span>
												Replied by: {item?.replied_by?.name}
											</p>
											<p className="">{item?.reply_date}</p>
										</div>
										<Divider>
											<BsFillChatTextFill className="text-green-400" />
										</Divider>
										<p className="text-gray-500 font-spec">
											{item?.reply_message}
										</p>
									</div>
								))}
								<Divider />
							</div>
						) : (
							<Empty description="No replies yet." />
						)}
					</div>

					{queryItem?.reply?.length === 2 ? null : (
						<form
							onSubmit={onSubmit}
							className="flex-col items-center justify-center lg:flex lg:flex-row lg:justify-around mx-auto"
						>
							<textarea
								className="w-full lg:w-3/5 shadow border rounded p-3 form-input mt-1  block ring-black outline-none focus:ring"
								placeholder="Enter Reply"
								type="text"
								spellCheck="true"
								value={queryMessage}
								onChange={(e) => setQueryMessage(e.target.value)}
								rows={2}
								maxLength="100"
							/>
							<div className="flex justify-center my-3">
								<Button
									type="primary"
									htmlType="submit"
									loading={loading}
									disabled={!queryMessage ? true : false}
									className="bg-[#00B4D8] text-white"
								>
									Reply
								</Button>
							</div>
						</form>
					)}

					<Divider />

					<div className="flex items-center justify-center space-x-4 my-4">
						<button
							type="button"
							onClick={() => resolveQuery("resolved")}
							//loading={resolveLoading === "resolved"}
							disabled={isDisabled}
							className="text-white bg-green-700 hover:bg-green-800 focus:outline-none focus:ring-4 focus:ring-green-300 font-medium rounded-md text-sm px-5 py-2.5 text-center mr-2 mb-2 dark:bg-green-600 dark:hover:bg-green-700 dark:focus:ring-green-800"
							title={isDisabled ? "Can't take action" : "Approve Query"}
						>
							{resolveLoading === "resolved" ? "Approving..." : "Resolve"}
						</button>
						<button
							type="button"
							title={isDisabled ? "Can't take action" : "Trash Query"}
							disabled={isDisabled}
							onClick={() => resolveQuery("terminated")}
							//loading={resolveLoading === "terminated"}
							className="text-white bg-red-700 hover:bg-red-800 focus:outline-none focus:ring-4 focus:ring-red-300 font-medium rounded-md text-sm px-5 py-2.5 text-center mr-2 mb-2 dark:bg-red-600 dark:hover:bg-red-700 dark:focus:ring-red-900"
						>
							{resolveLoading === "terminated" ? "Terminating..." : "Terminate"}
						</button>
					</div>
				</div>
			</div>
		</div>
	);
};

export default QueryHMOSummary;
